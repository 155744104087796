import "../styles/main.scss";

// LazyLoad
if (!("IntersectionObserver" in window)) {
	require("intersection-observer-amd");
}
import LazyLoad from "vanilla-lazyload/dist/lazyload.amd";
window.LazyLoad = new LazyLoad();

// LightBox
import GLightbox from 'glightbox';
window.GLightbox = new GLightbox();
